<template>
	<div id="login">
		<div class="login-wrap" :style="loginbg">
			<div class="login-con">
				<img v-if="config && config.login_front_back_url" class="login-con-left" :src="config.login_front_back_url" alt="">
				<img v-else class="login-con-left" src="@/assets/img/login-left.png" alt="">
				<div class="login-con-form">
					<div class="title">登录智慧农场管理后台</div>
					<div class="title-eng">Log in to the background of smart farm management</div>
					<div class="form">
						<form @submit.prevent="loginNow($event)">
							<div class="form-input" :class="focus == 1 ?'form-input-active':''">
								<i class="ri-user-2-line ri-lg ri-lg"></i>
								<p class="form-holder" :class="(focus == 1 || mobile !='' ) ?'active':''">请输入登录账号</p>
								<input type="text" v-model="mobile" @blur="onBlur" @focus="getFocus(1)">
							</div>
							<div class="form-input" :class="focus == 2 ?'form-input-active':''">
								<i class="ri-key-line ri-lg ri-lg"></i>
								<p class="form-holder" :class="(focus == 2 || password !='') ?'active':''">请输入登录密码</p>
								<input type="password" v-model="password"  @blur="onBlur" @focus="getFocus(2)">
							</div>
								<button type="submit" class="form-btn">立 即 登 录</button>
							<div class="go-register">
								<router-link to="/store/1">
									<span @click="toStoreLogin">商户登录</span>
								</router-link>
								<router-link to="/register">
									<span @click="toRegister">没有账号?立即注册</span>
								</router-link>
							</div>
							<div class="lc-tips">
								建议使用
								<a href="https://wxapp.cqkundian.com/ChromeSetup.exe" target="_blank" title="立即下载">谷歌浏览器</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-version" v-if="config"><span v-html="config.footer"></span></div>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import loginModel from '@/api/login.js'
import { useRoute } from 'vue-router';
export default {
	setup(){
		const _d = reactive({
			focus:"0",		//1=输入账号 2输入密码
			mobile:"",
			password:"",
			config:null,
		})
		const options = useRoute().query
		initData();
		//判断当前是否从云端登录
		if( options.code ){
			loginModel.kdCloudLogin(options.code);
		}
		//已存在token 直接登录成功
		if( localStorage.getItem('token') ){
			loginModel.loginSuccess();
		}
		//初始化获取配置信息
		function initData(){
			loginModel.getLoginSet().then(res=>{
				_d.config = res
			})
		}
		const loginbg = computed(()=>{
			if( _d.config ) return `background-image: url(${_d.config.login_back_url});`;
			return ""
		})

		//input失去焦点
		function onBlur(){
			_d.focus = 0
		}
		//input获得焦点
		function getFocus(e){
			_d.focus = e
		}
		//立即登录
		function loginNow(){
			loginModel.accountLogin(_d.mobile,_d.password);
		}

		return{
			...toRefs(_d),
			loginbg,
			onBlur,
			getFocus,
			loginNow
		}
	},
	
};
</script>
<style lang="scss" scoped>
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
.login-wrap {
	width: 100%;
	border: 1px solid green;
	position: fixed;
	height: 100%;
	text-align: center;
}


.login-con{
	width: 1000px;
	height: 500px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	display: flex;
	border-radius: 12px;

	&-left{
		width: 500px;
		height: 500px;
	}

	&-form{
		width: 500px;
		height: 500px;
		background: #fff;
		border-radius: 0 12px 12px 0;
		text-align: center;
		padding-top: 32px;

		.title{
			width: 100%;
			text-align: center;
			font-size: 24px;
			color: #1890ff;
			font-weight: bold;
		}
		.title-eng{
			color: #1890ff;
			font-size: 12px;
		}

		.form{
			width: 300px;
			margin-left: 100px;
			margin-top: 48px;

			.form-input{
				display: flex;
				align-items: center;
				height: 48px;
				border-bottom: 2px solid;
				padding: 0 12px;
				margin-bottom: 36px;
				border-bottom-color: rgba($color: #4a9ef4, $alpha: .6);

				>i{
					color: #4a9ef4;
				}
				
				input{
					width: 250px;
					height: 40px;
					margin-left: 12px;
					border: none;
					outline: none;
					font-size: 18px;
					background: none;
					z-index: 9;
				
					position: absolute;
					margin-left: 32px;
				}

				.form-holder{
					margin-left: 12px;
					font-size: 18px;
					color: #999;
					margin-top: 14px;
					transition: linear .2s;
				}

				.active{
					font-size: 12px;
					margin-top: -48px;
				}
			}
			.form-input-active{
				border-bottom-color: #4a9ef4;
			}
			.form-btn{
				width: 100%;
				height: 48px;
				border-radius: 6px;
				margin-top: 24px;
				border: none;
				background: #1890ff;
				color: #fff;
				font-size: 18px;
				cursor: pointer;
				transition: linear .2s;
				outline: none;
			}
			.form-btn:hover{
				background: rgba($color: #1890ff, $alpha: .8);
			}
		}
	}

	.lc-tips {
		font-size: 12px;
		color: #999;
		margin-top: 40px;

		a {
			text-decoration: none;
			color: #29adeb;
		}
	}
}
.footer-version {
	width: 100%;
	height: 30px;
	line-height: 30px;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	bottom: 0;
	text-align: center;
	color: #ffffff;
	font-size: 12px;

	p {
		line-height: 30px;
		margin: 0;
		padding: 0;
	}
}
.go-register {
	width: 100%;
	height: 50px;
	line-height: 50px;
	font-size: 12px;
	color: #4a4a4a;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	span:hover {
		color: #007aff;
	}
}
// 去掉input框自动填充背景色
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 32px #fff inset; /**通过边框阴影遮挡住背景*/
	-webkit-text-fill-color: #333; /*自动填充内容的文本颜色*/
}
</style>
